<template>
  <div class="yingsu">
    <el-header>
      <div class="lerf">
        <img src="../assets/qykt.jpg" alt="" @click="handleRouter('/index')">
      </div>
      <div class="right">
        <img :src="tx" alt="" />
        <span>{{ authority }}{{ name }}</span>
        <el-button type="primary" icon="el-icon-switch-button" :loading="loading" @click="quit">退出</el-button>
      </div>
    </el-header>
    <!-- <div class="daohang">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(item, key) in breadList" :key="key" :to="{ path: item.path }">
          {{ item.meta.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="metatitle">
        {{ breadList[1].meta.title }}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      tx: require("@/assets/tx.png"),
      name: "同学",
      authority: this.$cookie.get('username'),
      breadList: [], // 路由集合
      loading: false,
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  methods: {
    getBreadcrumb() {
      let matched = this.$route.matched;
      this.breadList = matched;
    },


    quit() {
      this.$message({
        showClose: true,
        message: "退出登录",
        type: "success",
      });
      this.$cookie.remove("token");
      this.$cookie.remove("type");
      this.$router.push("/login");
    },
  },
  created() {
    // this.getBreadcrumb();
    // this.onUserInfo();
  },
  mounted() { },
};
</script>

<style  lang="scss" scoped>
.lerf {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.yingsu {
  margin-bottom: 20px;

  .el-header {
    width: 100%;
    height: 78px !important;
    background-color: #fff;
    color: #2f2f32;
    border-bottom: 1px solid #d8d8d8;
    line-height: 60px;
    padding-right: 60px;
    display: flex;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;

      >img {
        height: 46px;
        margin: 0 10px;
        border-radius: 100px;
        object-fit: cover;
      }

      >span {
        font-size: 14px;
        font-weight: 400;
        color: #1e1e1e;
      }

      .el-button {
        background: #e3f2ed;
        margin-left: 40px;
        border-color: #e3f2ed;
        color: #0d9e72;
        font-weight: 700;

        >i {
          font-weight: 700;
        }
      }
    }
  }

  .daohang {
    background: #fff;
    padding: 20px;
    border-bottom: 1px solid #fafafa;

    .metatitle {
      font-size: 20px;
      font-weight: bold;
      margin: 20px 0 0 0;
    }
  }
}
</style>